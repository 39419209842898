<template>
  <div>
    <v-row>
      <v-col class="col-md-10 pr-md-1 sm-6">
        <h1 class="font-weight-light mb-2">My Users</h1>
        <span class="overline">Manage Your Users here</span>
      </v-col>

      <v-col class="col-md-2 pr-md-1 fill-width">
        <v-btn @click="dialogCompose = true" class="primary">New</v-btn>
      </v-col>
    </v-row>

    <v-card class="py-2 pa-0">
      <v-data-table
        :headers="headers"
        :items="getMyCompanyUsers"
        item-key="id"
        dark
      >
        <template v-slot:item="{ item }">
          <tr @click="loadUserProfile(item)">
            <td>
              <v-img :src="item.imageURL" width="30px" height="30px" />
            </td>
            <td>{{ item.userName }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.status }}</td>
            <td>{{ new Date(item.dateAdded) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogCompose" width="500">
      <v-card>
        <v-card-title class="headline black" primary-title>
          New User
        </v-card-title>
        <v-card-text class="pa-5">
          <v-form ref="sendForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="newUser.userName"
              label="User Name"
              :rules="[rules.required]"
              autocomplete="name"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="newUser.email"
              label="User Email"
              :rules="[rules.required, rules.email]"
              autocomplete="email"
              outlined
              dense
            ></v-text-field>

            <v-file-input
              label="User Avator"
              :rules="[rules.required]"
              v-model="file"
              outlined
              dense
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn
            class="ml-auto"
            @click="dialogCompose = false"
            outlined
            color="primary"
            >Cancel</v-btn
          >
          <v-btn color="primary" :disabled="!valid" @click="validate"
            >Add User</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewUserProfile" width="400">
      <v-card>
        <v-card-title class="headline" primary-title>
          {{ activeUser.userName }}
        </v-card-title>
        <v-card-title>
          <b-img :src="activeUser.imageURL" width="350px" />
        </v-card-title>
        <v-card-text class="pa-5">
          <p>
            EMAIL: {{ activeUser.email }}
            <br />
            DATE: {{ new Date(activeUser.dateAdded) }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn color="red" @click="deleteUser(activeUser)" outlined
            >Delete</v-btn
          >
          <v-btn
            class="ml-auto"
            @click="composeEmail(activeUser)"
            outlined
            color="primary"
            >Email</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComposeEmail" width="500">
      <v-card>
        <v-card-title class="headline black" primary-title>
          Compose Message
        </v-card-title>
        <v-card-text class="pa-5">
          <v-form ref="sendForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="activeUser.email"
              label="To"
              disabled
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              v-model="composeMessage.subject"
              label="Subject"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              :rules="[rules.required]"
              v-model="composeMessage.body"
              label="Message"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn
            class="ml-auto"
            @click="dialogComposeEmail = false"
            outlined
            color="primary"
            >Cancel</v-btn
          >
          <v-btn color="primary" :disabled="!valid" @click="validateEmail"
            >Send</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase";
import Vue from "vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  template: "#systemUsers",
  data: () => ({
    file: null,
    newUser: {},
    activeUser: {},
    composeMessage: {},
    dialogCompose: false,
    dialogComposeEmail: false,
    viewUserProfile: false,
    valid: false,
    rules: {
      required: (value) => !!value || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
    },
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/cdmi-ug.appspot.com/o/images%2Fpost_-LzYnh5Bq7OxjeaQI-hV_1580074978605?alt=media",
    headers: [
      { text: "Image", value: "imageTitle" },
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Status", value: "status" },
      { text: "Date Added", value: "dateAdded" },
    ],
  }),

  beforeCreate() {
    this.$store.dispatch("fetchMyCompanyUsers");
  },

  computed: {
    ...mapState(["myCompanyUsers"]),
    ...mapGetters(["getMyCompanyUsers", "messageAlert"]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyCompanyUsers");
      }
    },
  },

  methods: {
    loadUserProfile(user) {
      this.activeUser = user;
      this.viewUserProfile = true;
    },

    deleteUser(activeUser) {
      alert("Deleting");
    },

    composeEmail(user) {
      this.viewUserProfile = false;
      this.dialogComposeEmail = true;
    },

    validateEmail() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.sendEmail(this.newUser);
      }
    },

    sendEmail() {
      alert("Sending");
    },

    validate() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.createUser(this.newUser);
      }
    },

    createUser(newUser) {
      //send the message via API/server endpoint here
      this.dialogCompose = false;
      this.$store.dispatch("createNewCompanyUserProfile", {
        user: newUser,
        file: this.file,
      });
    },
  },
};
</script>
